import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import { MdPersonOutline } from "react-icons/md";

import styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to="/">{siteTitle}</Link>
      </div>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Link to="/about/">
          <Icon symbol="person" />
        </Link>
      </button>

      <nav className={cn(styles.nav)}>
        <ul>
          <li />
        </ul>
      </nav>
    </div>
  </div>
);

export default Header;
