import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockContent from '../components/block-content'

// 7jsyf*VQYeAij!2

export const query = graphql`
  query AboutPageQuery {
    person: allSanityPerson(limit: 1) {
      edges {
        node {
          id
          _rawBio
          name
          image {
            caption
            alt
            asset {
              _id
            }
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const personNode = data && data.person && mapEdgesToNodes(data.person)[0]
  return (
    <Layout>
      <SEO title='About' />
      <Container>
        <img
          src={imageUrlFor(buildImageObj(personNode.image))
            .width(Math.floor((2 / 8) * 1200))
            .height(Math.floor((2 / 8) * 1200))
            .fit('crop')
            .url()}
          alt={personNode.image.alt}
        />
        {personNode && <BlockContent blocks={personNode._rawBio || []} />}
      </Container>
    </Layout>
  )
}

export default AboutPage
