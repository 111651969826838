import React from "react";
import HamburgerIcon from "./hamburger";
import PersonIcon from "./person";

function Icon(props) {
  switch (props.symbol) {
    case "hamburger":
      return <HamburgerIcon />;
    case "person":
      return <PersonIcon />;
      break;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
