import React from "react";

const PersonIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 164.000000 164.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000, 230.000000) scale(0.09,-0.10)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1035 2244 c-131 -21 -256 -84 -350 -179 -85 -86 -130 -160 -164
-268 -25 -82 -25 -83 -25 -505 -1 -408 0 -424 18 -438 11 -8 30 -14 43 -14
l23 0 0 -391 0 -391 29 -29 29 -29 487 0 487 0 29 29 29 29 0 401 0 401 23 0
c13 0 31 6 42 13 17 13 18 37 16 423 -2 463 -5 486 -74 618 -74 143 -204 252
-365 306 -63 20 -213 34 -277 24z m262 -79 c196 -62 346 -228 388 -430 22
-105 22 -815 0 -815 -9 0 -15 10 -15 26 0 15 -12 38 -29 55 l-29 29 -174 0
-173 0 40 19 c140 64 261 235 294 415 23 123 11 277 -22 298 -7 4 -125 8 -263
8 -289 0 -265 -8 -308 106 -31 83 -54 109 -90 100 -23 -6 -46 -48 -46 -86 0
-9 -7 -39 -15 -67 l-15 -51 -79 -7 c-108 -9 -114 -17 -119 -145 -8 -190 50
-352 168 -471 37 -37 89 -77 119 -92 l55 -27 -173 0 -173 0 -29 -29 c-21 -22
-29 -39 -29 -65 0 -25 -4 -36 -15 -36 -13 0 -15 49 -15 383 0 228 4 407 11
443 15 83 79 212 139 277 151 166 386 229 597 162z m-281 -426 c25 -24 27 -24
280 -27 l254 -3 0 -112 c0 -130 -16 -202 -63 -290 -49 -93 -117 -161 -203
-203 -108 -54 -210 -54 -318 0 -111 55 -194 154 -242 288 -20 58 -34 195 -26
258 l7 55 78 6 c96 6 108 18 133 128 l17 74 28 -75 c16 -41 41 -86 55 -99z
m201 -864 c-3 -80 -12 -168 -22 -207 -17 -66 -47 -114 -66 -103 -38 24 -77
182 -86 345 l-6 105 93 0 93 0 -6 -140z m-233 53 c23 -194 31 -241 47 -295 35
-113 105 -155 163 -97 38 39 65 138 78 296 l12 138 161 -2 160 -3 0 -450 0
-450 -480 0 -480 0 -3 440 c-1 242 0 446 3 453 3 9 47 12 169 12 l165 0 5 -42z"
      />
      <path
        d="M713 690 c-28 -11 -32 -64 -7 -74 9 -3 55 -6 104 -6 65 0 91 4 100
15 17 21 4 61 -22 69 -34 9 -150 6 -175 -4z"
      />
    </g>
  </svg>
);

export default PersonIcon;
