import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage, getFixedGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import styles from "./figure.module.css";

export default ({ node }) => {
  if (!node.asset) {
    return null;
  }

  let fluidProps = getFluidGatsbyImage(node.asset._ref, {}, clientConfig.sanity);
  console.log(node);
  return (
    <>
      <div className={styles.styledImage}>
        <img src={imageUrlFor(buildImageObj(node)).url()} alt={node.asset._ref} />
      </div>

      {/* <Img
        fluid={fluidProps}
        alt={node.alt}
        // style={{
        //   maxHeight: "calc(54vh - 2rem)",
        //   maxWidth: "calc(59vw - 2rem",
        //   textAlign: "left",
        //   marginBottom: "1rem",
        //   marginTop: "1rem"
        // }}
        className={styles.styledImage}
        imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
      /> */}
      {node.caption && <figcaption>{node.caption}</figcaption>}
    </>
  );
};
